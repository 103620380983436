<mat-accordion [formGroup]="quoteForm">
	<mat-expansion-panel expanded="false">
		<mat-expansion-panel-header>
			<mat-panel-title> Installation Details </mat-panel-title>
			<mat-label class="mr-4">{{ this.installCost }}</mat-label>
		</mat-expansion-panel-header>

		<div *ngIf="installCheckedOnProject; else elseBlock">
			<span class="mb-2"> Part Total: {{ this.itemTotal | currency }} </span>
			<div class="flex flex-row space-x-2 mt-2">
				<ng-container [formGroup]="percentageGroup">
					<mat-form-field class="w-1/3">
						<mat-label> Percentage</mat-label>
						<input
							matInput
							type="number"
							(wheel)="$event.preventDefault()"
							formControlName="percentageControl"
							(keyup)="percentageChanged()"
							(blur)="trailingZeroCheck()"
						/>
						<span matSuffix class="mr-3 text-gray-500">%</span>
					</mat-form-field>
				</ng-container>

				<ng-container [formGroup]="installLineItem">
					<mat-form-field class="w-1/3">
						<span matPrefix class="ml-3 text-gray-500">$</span>
						<mat-label> Estimated Install Cost</mat-label>
						<input
							matInput
							type="number"
							(wheel)="$event.preventDefault()"
							formControlName="price"
							(keyup)="estimatedCostChanged()"
							(blur)="trailingZeroCheck()"
						/>
						<mat-error *ngIf="installLineItem?.get('price')?.touched && installLineItem?.get('price')?.invalid">
							Estimated Cost is required
						</mat-error>
					</mat-form-field>
				</ng-container>
			</div>

			<div class="flex space-x-2" [formGroup]="quoteForm">
				<mat-form-field class="w-1/3">
					<mat-label>Duration</mat-label>
					<input type="number" (wheel)="$event.preventDefault()" formControlName="installDays" matInput />
				</mat-form-field>

				<mat-form-field class="w-1/3">
					<mat-label>No. of Installers</mat-label>
					<input type="number" (wheel)="$event.preventDefault()" formControlName="installers" matInput />
				</mat-form-field>
			</div>
			<div>
				<mat-form-field class="w-full">
					<mat-label>Install Comment Selector</mat-label>
					<mat-select (selectionChange)="installOptionSelect($event)" #commentSelect>
						<mat-select-trigger>Selection Displayed Below:</mat-select-trigger>
						<mat-option *ngFor="let option of installCommentOptions" [value]="option.description">
							{{ option.description }}
						</mat-option>
						<mat-option *ngIf="!installCommentOptions.length">None</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field class="w-full">
					<mat-label>Install Comment</mat-label>
					<textarea matInput formControlName="installComment"></textarea>
				</mat-form-field>
			</div>
		</div>

		<ng-template #elseBlock>
			<span class="ml-3">'Include Install' not selected on project overview</span>
		</ng-template>
	</mat-expansion-panel>
</mat-accordion>
