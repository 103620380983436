<mat-expansion-panel>
	<mat-expansion-panel-header class="flex justify-between">
		<mat-panel-title>Miscellaneous Materials</mat-panel-title>
		<mat-label class="mr-4">{{ quoteForm.get('miscMaterialTotal')?.value | currency }}</mat-label>
	</mat-expansion-panel-header>

	<div *ngIf="miscMaterials.value.length; else elseBlock" [formGroup]="quoteForm">
		<ng-container formArrayName="miscMaterials">
			<div *ngFor="let material of miscMaterials.controls; let i = index" class="ml-4">
				<div [formGroupName]="i" class="flex space-x-2 w-full">
					<mat-form-field class="w-[45%]">
						<mat-label>Description</mat-label>
						<input type="text" formControlName="description" matInput />
					</mat-form-field>

					<mat-form-field class="w-1/5">
						<mat-label>Price</mat-label>
						<input type="number" (wheel)="$event.preventDefault()" formControlName="price" matInput />
					</mat-form-field>

					<mat-form-field class="w-1/5">
						<mat-label>Weight</mat-label>
						<input type="number" (wheel)="$event.preventDefault()" formControlName="weight" matInput />
					</mat-form-field>

					<button (click)="removeMaterial(i)" mat-icon-button><mat-icon>delete</mat-icon></button>
				</div>
			</div>
		</ng-container>

		<div class="flex justify-end w-3/4">
			<button mat-mini-fab matTooltip="Add new part" (click)="addMiscMaterial()" type="button" color="primary">
				<mat-icon>add</mat-icon>
			</button>
		</div>
	</div>
	<ng-template #elseBlock>
		<div class="ml-10 mb-2 flex items-center space-x-4">
			<span class="text-lg">No miscellaneous materials added</span>
			<button mat-mini-fab matTooltip="Add new part" (click)="addMiscMaterial()" type="button" color="primary">
				<mat-icon>add</mat-icon>
			</button>
		</div>
	</ng-template>
</mat-expansion-panel>
