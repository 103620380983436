<h2 mat-dialog-title>Create Price Level</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
	<mat-form-field class="w-1/4 pt-2 pr-2">
		<mat-label>Price Level Name</mat-label>
		<input matInput formControlName="name" />
		<mat-error *ngIf="form.get('name')?.hasError('maxlength')">
			{{ this.form.get('name')?.value.length }} / 100 Maximum Characters Allowed</mat-error
		>
		<mat-error *ngIf="form.get('name')?.hasError('required')"> Name is required </mat-error>
	</mat-form-field>
	<mat-form-field class="w-1/2 pt-2">
		<mat-label>Level Number</mat-label>
		<input type="number" (wheel)="$event.preventDefault()" matInput formControlName="levelNumber" />
		<mat-error *ngIf="form.get('levelNumber')?.hasError('required')"> Level number is required </mat-error>
	</mat-form-field>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createPriceLevel()">Create</button>
</div>
