<div class="flex flex-row" *ngIf="handlingUnitForm" [formGroup]="handlingUnitForm">
	<mat-form-field appearance="outline" class="mr-2" [style.width]="'10rem'">
		<mat-label>Handling Unit</mat-label>
		<mat-select [formControlName]="huType">
			<mat-option [value]="handlingUnit.value" *ngFor="let handlingUnit of huTypeList">{{ handlingUnit.description }}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="outline" [style.width]="'3.5rem'">
		<mat-label>L</mat-label>
		<input
			matInput
			type="number"
			(wheel)="$event.preventDefault()"
			[formControlName]="length"
			(change)="recalcFreight()"
			[readonly]="readOnly"
		/>
	</mat-form-field>
	<span class="mt-4">x</span>
	<mat-form-field appearance="outline" [style.width]="'3.5rem'">
		<mat-label>W</mat-label>
		<input
			matInput
			type="number"
			(wheel)="$event.preventDefault()"
			[formControlName]="width"
			(change)="recalcFreight()"
			[readonly]="readOnly"
		/>
	</mat-form-field>
	<span class="mt-4">x</span>
	<mat-form-field appearance="outline" [style.width]="'3.5rem'">
		<mat-label>H</mat-label>
		<input
			matInput
			type="number"
			(wheel)="$event.preventDefault()"
			[formControlName]="height"
			(change)="recalcFreight()"
			[readonly]="readOnly"
		/>
	</mat-form-field>
	<mat-form-field appearance="outline" class="mr-2" [style.width]="'7rem'">
		<mat-label>Unit</mat-label>
		<mat-select [formControlName]="dUnit" (selectionChange)="recalcFreight('dimension')">
			<mat-option [value]="dUnit" *ngFor="let dUnit of dUnitList">{{ dUnit }}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="outline" [style.width]="'6rem'">
		<mat-label>Weight</mat-label>
		<input
			matInput
			type="number"
			(wheel)="$event.preventDefault()"
			[formControlName]="weight"
			(change)="recalcFreight()"
			[readonly]="readOnly"
		/>
	</mat-form-field>
	<mat-form-field appearance="outline" class="mr-2" [style.width]="'7rem'">
		<mat-label>Unit</mat-label>
		<mat-select [formControlName]="wUnit" (selectionChange)="recalcFreight('weight')">
			<mat-option [value]="wUnit" *ngFor="let wUnit of wUnitList">{{ wUnit }}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="outline" [style.width]="'4rem'">
		<mat-label>Qty</mat-label>
		<input
			matInput
			type="number"
			(wheel)="$event.preventDefault()"
			[formControlName]="qty"
			(change)="recalcFreight()"
			[readonly]="readOnly"
		/>
	</mat-form-field>
	<button mat-button (click)="deleteHandlingUnit()" class="mt-2 ml-2" *ngIf="!readOnly">
		<mat-icon class="my-2">delete</mat-icon>
	</button>
</div>
