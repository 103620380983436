<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">{{ status.description | titlecase }} Department Statuses</span>
		<button mat-icon-button (click)="form.enable()" [disabled]="form.enabled">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
	<div class="pt-4">
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Status</mat-label>
				<input matInput formControlName="status" />
			</mat-form-field>
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Description</mat-label>
				<input matInput formControlName="description" />
			</mat-form-field>
			<button (click)="openConfirmDeleteModal(status)" class="pb-3"><mat-icon>delete</mat-icon></button>
		</div>
		<div *ngIf="statuses && statuses.length" formArrayName="statuses">
			<h3>Sub Statuses</h3>
			<div *ngFor="let substatus of statuses.controls; index as i" [formGroupName]="i" class="flex gap-4 justify-around">
				<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
					<mat-label>Status #</mat-label>
					<input matInput type="number" (wheel)="$event.preventDefault()" formControlName="status" />
				</mat-form-field>
				<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
					<mat-label>Status Description</mat-label>
					<input matInput formControlName="description" />
				</mat-form-field>
				<button (click)="openConfirmDeleteModal(substatus)" class="pb-3"><mat-icon>delete</mat-icon></button>
			</div>
		</div>
		<div (click)="form.enable()" class="w-fit">
			<button [disabled]="form.disabled" mat-mini-fab color="primary" class="m-auto" (click)="addNewStatus()">
				<mat-icon>add</mat-icon>
			</button>
		</div>
	</div>
	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
