<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">Price Level {{ priceLevel.levelNumber }}</span>
	</div>
	<div class="flex pt-4">
		<div class="mt-4" formArrayName="priceLevelDiscounts">
			<button mat-button color="primary" class="text-sm mb-4" (click)="addDiscount()">
				<mat-icon class="text-xs">add_circle</mat-icon>Add Discount
			</button>
			<div *ngFor="let discount of discounts.controls; let i = index" class="mb-2">
				<div [formGroupName]="i" class="flex flex-row space-x-2 w-full">
					<mat-form-field class="w-1/2" appearance="outline">
						<mat-label>Effective Date</mat-label>
						<input matInput [matDatepicker]="picker" formControlName="effectiveDate" />
						<mat-error *ngIf="discounts.at(i).get('effectiveDate')?.hasError('required')">
							Effective Date is <strong>required</strong>
						</mat-error>
						<mat-error *ngIf="discounts.at(i).get('effectiveDate')?.hasError('invalidDate')"> Date must not be before today </mat-error>
						<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>

					<mat-form-field appearance="outline" class="w-1/5">
						<mat-label>Discount</mat-label>
						<input type="number" (wheel)="$event.preventDefault()" min="0" max="100" matInput formControlName="levelDiscount" />
						<span matSuffix class="mr-3 text-gray-500">%</span>
					</mat-form-field>
					<button class="mb-4" *ngIf="discount.enabled">
						<mat-icon (click)="deleteDiscount(i)">delete</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
