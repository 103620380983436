<div class="flex flex-col" *ngIf="siteForm" [formGroup]="siteForm">
	<div class="flex flex-row" *ngIf="!readOnly && !disableSelection">
		<mat-form-field appearance="outline" class="flex-1">
			<mat-label>Site</mat-label>
			<mat-select [value]="selectedSite" (selectionChange)="selectSite($event)" [disabled]="clientSites.length < 1">
				<mat-option></mat-option>
				<mat-option [value]="site" *ngFor="let site of clientSites">{{ site.name }}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-spinner *ngIf="busy" class="spinner m-2" diameter="20"></mat-spinner>
		<button mat-icon-button *ngIf="canCreate && !selectedSite.id" [disabled]="busy || createDisabled" (click)="createSite()">
			<mat-icon>add_circle</mat-icon>
		</button>
		<button
			mat-icon-button
			*ngIf="canModify && selectedSite.id"
			[disabled]="busy || modifyDisabled || !!selectedSite.locationId"
			(click)="modifySite()"
		>
			<mat-icon>save</mat-icon>
		</button>
		<button mat-icon-button *ngIf="canModify && selectedSite.id" [disabled]="busy || modifyDisabled" (click)="revertSite()">
			<mat-icon>restore</mat-icon>
		</button>
	</div>

	<mat-form-field appearance="outline">
		<mat-label>Name</mat-label>
		<input matInput type="text" [formControlName]="name" [readonly]="readOnly" />
		<mat-error>Name <strong>required</strong>.</mat-error>
	</mat-form-field>

	<mat-form-field appearance="outline">
		<mat-label>Address Line 1</mat-label>
		<input matInput type="text" [formControlName]="address1" [readonly]="readOnly" />
		<mat-error>Address <strong>required</strong>.</mat-error>
	</mat-form-field>

	<mat-form-field appearance="outline">
		<mat-label>Address Line 2</mat-label>
		<input matInput type="text" [formControlName]="address2" [readonly]="readOnly" />
	</mat-form-field>

	<div class="flex flex-row">
		<mat-form-field appearance="outline" class="w-4/12 mr-2">
			<mat-label>City</mat-label>
			<input matInput type="text" [formControlName]="city" [readonly]="readOnly" />
			<mat-error>City <strong>required</strong>.</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-2/12 mr-2">
			<mat-label>State</mat-label>
			<input matInput type="text" [formControlName]="state" [readonly]="readOnly" />
			<mat-error>State <strong>required</strong>.</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-3/12 mr-2">
			<mat-label>Zip</mat-label>
			<input matInput type="text" [formControlName]="postal" [readonly]="readOnly" />
			<mat-error>Zip <strong>required</strong>.</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-3/12">
			<mat-label>Country</mat-label>
			<input matInput type="text" [formControlName]="country" [readonly]="readOnly" />
			<mat-error>Country <strong>required</strong>.</mat-error>
		</mat-form-field>
	</div>
</div>
