import {Component, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {ShippingLogService} from '../core/services/shipping-log.service';
import {ShippingLog} from '../core/models/shipping-log.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Code} from '../core/models/code.interface';
import {CodeService} from '../core/services/code.service';
import {CodeTypeEnum} from '../core/enums/code-type.enum';
import {DatePipe} from '@angular/common';
import {MatDatepicker} from '@angular/material/datepicker';

@Component({
	selector: 'app-shipping-log',
	templateUrl: './shipping-log.component.html',
	styleUrls: ['./shipping-log.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class ShippingLogComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<ShippingLog> = new MatTableDataSource<ShippingLog>();
	columnsToDisplay: string[] = [
		'accountManager',
		'job',
		'budget',
		'cost',
		'profitDollar',
		'profitPercent',
		'broker',
		'delivery',
		'invoiceDate'
	];

	shippingLog: ShippingLog[] = [];

	codes: Code[] = [];

	busy: boolean = false;
	loading: boolean = false;

	today: Date = new Date();
	minDate: Date = new Date('2023-03-15');
	month: Date = this.today;
	monthString: string = this.month.toISOString();

	readonly date: string = '';

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private shippingLogService: ShippingLogService,
		private _snackBar: MatSnackBar,
		private codeService: CodeService
	) {}

	ngOnInit(): void {
		this.codeService.findByType(CodeTypeEnum.SHIPPING_ADDITIONAL_COST_REASON).subscribe({
			next: (codes): void => {
				this.codes = codes;
			},
			error: (err: any): void => {}
		});
		this.getShippingLog();
	}

	getShippingLog() {
		this.shippingLogService.getShippingLog(this.month).subscribe({
			next: (shippingLog) => {
				this.shippingLog = shippingLog;
				this.setData();
				this.busy = false;
				this.loading = false;
			},
			error: (err) => {
				this._snackBar.open('Get Shipping Log Failed: ' + err.error.message);
				this.busy = false;
				this.loading = false;
			}
		});
	}

	setData() {
		const datePipe = new DatePipe(this.locale);
		this.shippingLog.forEach((log) => {
			log.profitDollar = this.profitDollar(log);
			log.profitPercent = this.profitPercent(log);
			if (log.additionalCostReason) {
				log.additionalCostReasonString = this.additionalCostReason(log.additionalCostReason);
			}
			log.searchData = [
				datePipe.transform(log.shipDate, 'shortDate'),
				datePipe.transform(log.shipDate, 'fullDate'),
				datePipe.transform(log.deliveryDate, 'shortDate'),
				datePipe.transform(log.deliveryDate, 'fullDate'),
				datePipe.transform(log.originalDeliveryDate, 'shortDate'),
				datePipe.transform(log.originalDeliveryDate, 'fullDate')
			].join(' ');
		});
		this.dataSource = new MatTableDataSource<ShippingLog>(this.shippingLog);
		this.dataSource.sortingDataAccessor = (item: any, property) => {
			switch (property) {
				case 'job':
					return item.orderNo;
				case 'broker':
					return item.brokerName;
				case 'delivery':
					return item.originalDeliveryDate;
				default:
					return item[property] ? item[property] : '';
			}
		};
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	showDelivery(log: ShippingLog): boolean {
		return log.originalDeliveryTime === log.deliveryTime && log.originalDeliveryDate === log.deliveryDate;
	}

	onTime(log: ShippingLog): boolean {
		let onTime = false;
		if (log.originalDeliveryTime && log.deliveryTime && log.originalDeliveryDate && log.deliveryDate) {
			if (log.deliveryDate < log.originalDeliveryDate) {
				onTime = true;
			} else if (log.originalDeliveryDate === log.deliveryDate && log.deliveryTime <= log.originalDeliveryTime) {
				onTime = true;
			}
		}
		return onTime;
	}

	updateLog(updatedLog: ShippingLog) {
		const updateIndex = this.shippingLog.findIndex((log) => log.id === updatedLog.id);
		if (updateIndex >= 0) {
			this.shippingLog[updateIndex] = updatedLog;
			this.shippingLog[updateIndex].expanded = false;
			this.setData();
		}
	}

	profitDollar(log: ShippingLog) {
		let budget = 0;
		let cost = 0;
		if (log.budget) {
			budget = log.budget;
		}
		if (log.cost) {
			cost = log.cost;
		}
		if (log.additionalCost) {
			cost += log.additionalCost;
		}
		return budget - cost;
	}

	profitPercent(log: ShippingLog) {
		let budget = 0;
		let cost = 0;
		if (log.budget) {
			budget = log.budget;
		}
		if (log.cost) {
			cost = log.cost;
		}
		if (log.additionalCost) {
			cost += log.additionalCost;
		}
		if (budget) {
			return (budget - cost) / budget;
		} else {
			return 0;
		}
	}

	get avgProfit(): number {
		let percent = 0;
		let count = this.dataSource.filteredData.filter((log) => log.cost && log.budget).length;
		this.dataSource.filteredData.forEach((log) => {
			if (log.cost && log.budget) {
				let additionalCost = 0;
				if (log.additionalCost) {
					additionalCost = log.additionalCost;
				}
				percent += (log.budget - (log.cost + additionalCost)) / log.budget;
			}
		});
		return percent / count;
	}

	get weightedProfit(): number {
		let cost = 0;
		let budget = 0;
		this.dataSource.filteredData.forEach((log) => {
			if (log.cost) {
				cost += log.cost;
			}
			if (log.budget) {
				budget += log.budget;
			}
			if (log.additionalCost) {
				cost += log.additionalCost;
			}
		});
		return (budget - cost) / budget;
	}

	additionalCostReason(code: string) {
		let codeDescription = '';
		if (this.codes) {
			const selectedCode = this.codes.find((c) => c.code === code);
			if (selectedCode && selectedCode.codeDescription) {
				codeDescription = selectedCode.codeDescription;
			}
		}
		return codeDescription;
	}

	setMonthAndYear(month: Date, datepicker: MatDatepicker<Date>) {
		this.month = month;
		this.monthString = month.toISOString();
		this.getShippingLog();
		datepicker.close();
	}

	get displayMonth() {
		return this.month.toLocaleString('en-US', {month: 'short', year: 'numeric'});
	}
}
