<mat-accordion [formGroup]="quoteForm">
	<mat-expansion-panel expanded>
		<mat-expansion-panel-header>
			<mat-panel-title *ngIf="ngForm.submitted && quoteForm.get('lineItemParts')?.invalid" class="text-red">
				Manual Part Entry*
			</mat-panel-title>
			<mat-panel-title *ngIf="!ngForm.submitted || quoteForm.get('lineItemParts')?.valid"> Manual Part Entry </mat-panel-title>
			<mat-label class="mr-2" *ngIf="lineItemPartsFormArray.length">{{ lineItemPartsFormArray.length }} items added</mat-label>
		</mat-expansion-panel-header>
		<mat-slide-toggle class="mb-2" color="primary" (toggleChange)="flipMobilePartsFilter()" [disabled]="submitted"
			>Show only mobile parts</mat-slide-toggle
		>
		<app-manual-parts-input
			[submitted]="submitted"
			[previouslySelectedParts]="lineItemPartsFormArray.value ?? []"
			[partDeletedSubject]="partDeletedSubject"
			[filterMobilePartsSubject]="filterMobilePartsSubject"
			[partsLoading]="partsLoading"
			(result)="selectChange($event)"
		>
		</app-manual-parts-input>

		<ng-container *ngIf="!lineItemPartsFormArray.length">
			<span class="ml-2 my-2 font-bold">No Parts added</span>
		</ng-container>

		<ng-container *ngIf="lineItemPartsFormArray.length">
			<span class="text-xl"> Selected {{ partType }} Parts </span>

			<div class="flex flex-row space-x-4 font-bold my-2">
				<span class="w-1/4"> Item No. </span>
				<span class="w-1/3"> Description </span>
				<span class="w-1/6"></span>
				<span class="w-1/6"></span>
			</div>

			<mat-divider class="mb-2"></mat-divider>

			<div class="mt-2 max-h-[34vh] overflow-y-auto">
				<div formArrayName="lineItemParts" *ngFor="let part of this.lineItemPartsFormArray.controls; let i = index">
					<div class="flex flex-row space-x-4 space-y-1 items-center" [formGroupName]="i">
						<span class="w-1/4">{{ part.value.erpItemRef }}</span>
						<span class="w-1/3">{{ part.value.description }}</span>
						<mat-form-field class="w-1/6 mb-2">
							<mat-label> Qty </mat-label>
							<input type="number" (wheel)="$event.preventDefault()" matInput formControlName="qty" />
						</mat-form-field>
						<span class="w-1/6 -translate-y-2.5" matTooltip="Disabled until parts are loaded" [matTooltipDisabled]="!partsLoading">
							<button (click)="delete(i)" [disabled]="partsLoading" type="button">
								<mat-icon *ngIf="submitted" class="text-gray-500">delete</mat-icon>
								<mat-icon *ngIf="!submitted">delete</mat-icon>
							</button>
						</span>
					</div>
				</div>
			</div>

			<div class="ml-2"></div>
		</ng-container>

		<div class="flex flex-row place-content-end space-x-2 mt-4">
			<button
				type="button"
				matTooltip="Reload parts from ERP system"
				mat-stroked-button
				[disabled]="partsLoading"
				(click)="reloadParts.emit()"
			>
				<mat-icon [ngClass]="{'loop-spin': partsLoading}">loop</mat-icon>
				Reload Parts
			</button>
		</div>

		<div class="w-full my-4">
			<mat-divider></mat-divider>
		</div>

		<div [formGroup]="group" class="flex flex-col">
			<span class="text-xl">Material Cost Surcharge</span>
			<span class="mt-2">Mobile Parts Total Cost: {{ materialCostTotal | currency }}</span>
			<div class="flex space-x-6 mt-4">
				<mat-form-field class="w-1/2">
					<mat-label>Percentage</mat-label>
					<mat-select (selectionChange)="onPercentageSelected($event.value)" [(value)]="selected">
						<mat-option *ngFor="let percentage of percentageOptions" [value]="percentage">{{ percentage.codeDescription }}%</mat-option>
					</mat-select>
				</mat-form-field>
				<div class="flex flex-col mt-0.5">
					<span class="tex-xs">Total: </span>
					<span class="text-xl">{{ group.get('price')?.value | currency }}</span>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
