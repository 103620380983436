<div [formGroup]="freightOption">
	<mat-accordion>
		<mat-expansion-panel expanded="false" class="w-full">
			<mat-expansion-panel-header>
				<mat-panel-title
					*ngIf="
						!ngForm.submitted ||
						(quoteForm.get('freightOption')?.get('weight')?.valid && quoteForm.get('freightOption')?.get('price')?.valid)
					"
				>
					Freight Details
				</mat-panel-title>
				<mat-panel-title
					*ngIf="
						ngForm.submitted &&
						(quoteForm.get('freightOption')?.get('weight')?.invalid || quoteForm.get('freightOption')?.get('price')?.invalid)
					"
					class="text-red"
				>
					Freight Details*
				</mat-panel-title>
				<mat-label class="mr-4" *ngIf="!!this.quoteForm.get('freightOption')?.get('price')?.value">{{ this.freightCost }}</mat-label>
				<mat-label class="mr-4" *ngIf="!this.quoteForm.get('freightOption')?.get('price')?.value">$0.00</mat-label>
			</mat-expansion-panel-header>

			<mat-form-field appearance="outline" class="w-full">
				<mat-label>Freight Options</mat-label>
				<mat-select formControlName="description">
					<mat-option *ngFor="let code of glCodes" [value]="code.description">
						{{ code.glNumber }}&nbsp;-&nbsp;{{ code.description }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div class="flex flex-row space-x-4">
				<mat-form-field appearance="outline" class="w-5/12">
					<mat-label>Est. Skidding Weight</mat-label>
					<input matInput type="number" (wheel)="$event.preventDefault()" formControlName="weight" />
					<span matSuffix class="mr-3 text-gray-500">lbs</span>
					<mat-error
						*ngIf="quoteForm.get('freightOption')?.get('weight')?.touched && quoteForm.get('freightOption')?.get('weight')?.invalid"
					>
						Weight is required
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="w-5/12">
					<span matPrefix class="ml-3 text-gray-500">$</span>
					<mat-label> Estimated Cost</mat-label>
					<input matInput type="text" formControlName="price" IsDecimal (blur)="checkForTrailingZero()" />
					<mat-error *ngIf="quoteForm.get('freightOption')?.get('price')?.touched && quoteForm.get('freightOption')?.get('price')?.invalid">
						Estimated Cost is required
					</mat-error>
					<mat-error *ngIf="ngForm.submitted && quoteForm.get('freightOption')?.get('price')?.invalid">
						Estimated Cost is required
					</mat-error>
				</mat-form-field>
				<form [formGroup]="noFreightCostForm" class="mt-2 ml-2 w-1/2">
					<mat-label *ngIf="!submitted"> 3rd Party Freight</mat-label>
					<mat-label *ngIf="submitted" class="text-gray-500"> 3rd Party Freight</mat-label>
					<mat-checkbox
						formControlName="noFreightCost"
						(click)="thirdPartyFreightSelected.emit(noFreightCostForm.get('noFreightCost')?.value)"
					></mat-checkbox>
				</form>
			</div>

			<ng-container *ngIf="quote.accessorials" [formGroup]="quoteForm">
				<app-accessorials
					[selectedAccessorialArray]="quote.accessorials"
					[submitted]="submitted"
					[isNestedForm]="false"
					componentFormArrayName="accessorials"
					[quoteId]="quote.id!"
				></app-accessorials>
			</ng-container>
		</mat-expansion-panel>
	</mat-accordion>
</div>
