<div class="freight">
	<div class="flex flex-row justify-between items-center p-4">
		<div>
			<mat-form-field appearance="outline" class="mr-2" subscriptSizing="dynamic">
				<mat-label class="font-medium">Filter</mat-label>
				<input matInput [(ngModel)]="filter" />
			</mat-form-field>
			<mat-button-toggle-group [(ngModel)]="filterType" class="mr-2">
				<mat-button-toggle value="">All</mat-button-toggle>
				<mat-button-toggle value="BOOKED">Booked</mat-button-toggle>
				<mat-button-toggle value="AWAIT_BOOK">Await Booking</mat-button-toggle>
				<mat-button-toggle value="RATES_REQUESTED">Rates Requested</mat-button-toggle>
				<mat-button-toggle value="READY_TO_RATE">Ready to Rate</mat-button-toggle>
				<mat-button-toggle value="INVALID">Invalid</mat-button-toggle>
			</mat-button-toggle-group>
			<mat-button-toggle-group *ngIf="locations.length" [(ngModel)]="locationFilter" class="mr-2">
				<mat-button-toggle value="">All</mat-button-toggle>
				<mat-button-toggle *ngFor="let location of locations" [value]="location">{{ location }}</mat-button-toggle>
			</mat-button-toggle-group>
			<mat-button-toggle-group [(ngModel)]="current" *ngIf="!projectId" [disabled]="busy" (ngModelChange)="getFreight()">
				<mat-button-toggle [value]="true">Current</mat-button-toggle>
				<mat-button-toggle [value]="false">Old</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div class="flex">
			<button mat-button routerLink="/brokers" color="primary" class="mb-0">Brokers</button>
			<button mat-button routerLink="/shipping-log" color="primary" class="ml-2 mb-0">Shipping Log</button>
			<button mat-stroked-button class="mx-2" [disabled]="busy" (click)="getFreight()">
				<mat-icon *ngIf="busy"><mat-spinner diameter="15"></mat-spinner></mat-icon>
				<mat-icon *ngIf="!busy">refresh</mat-icon>
				Refresh
			</button>
			<button mat-raised-button color="primary" (click)="navigateToNewShipment()">New Shipment</button>
		</div>
	</div>
	<div class="flex flex-row">
		<mat-accordion class="top w-3/5">
			<mat-expansion-panel
				#freightPanel
				*ngFor="let freight of filteredFreightList; let i = index"
				[(expanded)]="freight.expanded"
				(expandedChange)="expandChange($event, i)"
				[ngClass]="{'z-0 bg-gray-50': !freight.expanded, 'z-20': freight.expanded, 'not-ready': !freight.ready || freight.booked}"
			>
				<mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
					<mat-panel-title class="font-medium text-lg">
						<div class="flex flex-1 items-start justify-between">
							<div class="pl-4 pt-2">
								<div class="flex" *ngIf="!freight.editDate">
									<div class="pt-2">{{ freight.deliveryDate }}</div>
									<button *ngIf="freight.ready" class="text-sm" mat-icon-button (click)="freight.editDate = true; $event.stopPropagation()">
										<mat-icon class="text-sm">edit</mat-icon>
									</button>
									<div *ngIf="!freight.ready" class="w-12 h-12"></div>
								</div>
								<div class="flex" *ngIf="freight.editDate">
									<mat-form-field appearance="outline" class="w-40" subscriptSizing="dynamic">
										<mat-label class="font-medium">Delivery Date</mat-label>
										<input matInput [matDatepicker]="datepicker" [(ngModel)]="freight.newDate" (click)="$event.stopPropagation()" />
										<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
										<mat-datepicker #datepicker> </mat-datepicker>
										<mat-error>Date <strong>required</strong>.</mat-error>
									</mat-form-field>
									<button
										*ngIf="freight.editDate"
										mat-icon-button
										(click)="freight.editDate = false; $event.stopPropagation()"
										[disabled]="busy"
									>
										<mat-icon class="text-sm">cancel</mat-icon>
									</button>
									<button
										*ngIf="freight.editDate"
										mat-icon-button
										(click)="updateDate(freight); $event.stopPropagation()"
										[disabled]="busy"
									>
										<mat-icon class="text-sm">save</mat-icon>
									</button>
								</div>
							</div>
							<div class="flex flex-1 flex-col p-4">
								<span *ngIf="freight.orderInfo && freight.orderInfo > ''">{{ freight.orderInfo }}</span>
								<span>{{ freight.deliveryName }}</span>
								<span>{{ freight.deliveryAddress1 }}</span>
								<span>{{ freight.deliveryAddress2 }}</span>
								<div class="flex flex-row">
									{{ freight.deliveryCity }},
									{{ freight.deliveryState }}
									{{ freight.deliveryPostal }}
									{{ freight.deliveryCountry }}
								</div>
							</div>
							<div class="flex items-center p-4">
								<div *ngIf="freight.expired" class="flex flex-col items-end">
									<span>Expired</span>
								</div>
								<div *ngIf="freight.freightId === 0 && !freight.expired" class="flex flex-col items-end">
									<span>{{ validShipments(freight) }} of {{ freight.shipments.length }}</span>
									<span>Ready to Rate</span>
								</div>
								<div *ngIf="freight.freightId && !freight.booked && !freight.expired" class="flex flex-col items-end">
									<span>{{ freight.bidCount }} of {{ freight.rateRequestCount }}</span>
									<span>Rates Received</span>
								</div>
								<div *ngIf="freight.booked && !freight.bookAccepted && !freight.expired" class="flex flex-col items-end">
									<span>Await Booking Confirmation</span>
								</div>
								<div *ngIf="freight.booked && freight.bookAccepted && !freight.logged" class="flex flex-col items-end">
									<span>Booked</span>
								</div>
								<div *ngIf="freight.logged" class="flex flex-col items-end">
									<span>Logged</span>
								</div>
								<mat-icon *ngIf="freight.ready" class="m-2"></mat-icon>
								<mat-icon color="warn" *ngIf="!freight.ready" class="m-2">error</mat-icon>
							</div>
						</div>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex justify-end" *ngIf="freight.booked && freight.bookAccepted && !freight.logged">
					<button class="m-2" mat-raised-button color="primary" (click)="logFreight()">Log</button>
				</div>
				<div class="flex justify-end" *ngIf="freight.expired">
					<button class="m-2" mat-raised-button color="primary" (click)="removeFreight(i)">Remove</button>
				</div>
				<mat-accordion multi>
					<mat-expansion-panel *ngIf="freight.booked" expanded>
						<mat-expansion-panel-header>Booking</mat-expansion-panel-header>
						<div class="flex flex-col">
							<mat-divider></mat-divider>
							<div *ngFor="let booking of freight.bookings; let bI = index" class="flex flex-col sm:border-b">
								<div class="flex justify-between font-medium text-lg pt-4">
									<span *ngIf="booking.freightBooking">Booked</span>
									<span *ngIf="booking.bidStatus === 'booking-requested'">Awaiting Response</span>
									<div class="flex flex-col items-end">
										<span>{{ booking.amount | currency }}</span>
										<span class="text-base uppercase">({{ booking.currency }})</span>
									</div>
								</div>
								<div class="font-medium pt-2 pl-2">{{ brokerInfo(booking.brokerId) }}</div>
								<div class="flex justify-between">
									<div class="flex flex-col p-4">
										<span>
											Freight Bid #: <span class="font-medium">{{ booking.freightBidNo }}</span>
										</span>
										<span>
											Equipment Type: <span class="font-medium uppercase">{{ booking.equipmentType }}</span>
										</span>
										<span>
											Service Type: <span class="font-medium capitalize">{{ booking.serviceType }}</span>
										</span>
										<span>
											<span class="font-medium capitalize">{{ booking.indirectService ? 'Indirect' : 'Direct' }}</span>
										</span>
									</div>
									<div class="flex flex-col p-4">
										<span>
											Broker: <span class="font-medium">{{ booking.brokerName }}</span>
										</span>
										<span>
											SCAC: <span class="font-medium">{{ booking.brokerScac }}</span>
										</span>
										<span>
											Carrier: <span class="font-medium">{{ booking.carrierName }}</span>
										</span>
										<span>
											SCAC: <span class="font-medium">{{ booking.carrierScac }}</span>
										</span>
									</div>
									<div class="flex flex-col items-end flex-grow p-4">
										<span>
											Pickup Date: <span class="font-medium">{{ booking.pickupDatetime | date : 'fullDate' }}</span></span
										>
										<span>
											Delivery Date: <span class="font-medium">{{ booking.deliveryDatetime | date : 'fullDate' }}</span></span
										>
										<span *ngIf="booking.transitTime">
											Transit Time: <span class="font-medium">{{ booking.transitTime }}</span>
										</span>
									</div>
								</div>
								<div *ngIf="booking.freightBooking?.documentName && booking.id" class="flex pb-2">
									<a
										matTooltip="BOL"
										class="flex underline cursor-pointer font-medium"
										(click)="downloadFile($event, booking.freightBooking?.documentName, booking.id)"
									>
										<mat-icon class="">description</mat-icon>
										<div class="pb-1">{{ booking.freightBooking?.documentName }}</div>
									</a>
								</div>
								<div *ngIf="booking.comments" class="pb-2">Comments: {{ booking.comments }}</div>
							</div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel *ngIf="freight.freightId" [expanded]="!freight.booked">
						<mat-expansion-panel-header>Bids</mat-expansion-panel-header>
						<div class="flex flex-col">
							<mat-divider></mat-divider>
							<div *ngIf="freight.bidCount === 0" class="flex font-medium">No Bids</div>
							<div *ngFor="let bid of freight.bids; let bI = index" class="flex sm:border-b flex-col">
								<div class="font-medium pt-2 pl-2">{{ brokerInfo(bid.brokerId) }}</div>
								<div class="flex justify-between">
									<div class="flex flex-col p-4">
										<span>
											Freight Bid #: <span class="font-medium">{{ bid.freightBidNo }}</span>
										</span>
										<span>
											Equipment Type: <span class="font-medium uppercase">{{ bid.equipmentType }}</span>
										</span>
										<span>
											Service Type: <span class="font-medium capitalize">{{ bid.serviceType }}</span>
										</span>
										<span>
											<span class="font-medium capitalize">{{ bid.indirectService ? 'Indirect' : 'Direct' }}</span>
										</span>
									</div>
									<div class="flex flex-col p-4">
										<span>
											Broker: <span class="font-medium">{{ bid.brokerName }}</span>
										</span>
										<span>
											SCAC: <span class="font-medium">{{ bid.brokerScac }}</span>
										</span>
										<span>
											Carrier: <span class="font-medium">{{ bid.carrierName }}</span>
										</span>
										<span>
											SCAC: <span class="font-medium">{{ bid.carrierScac }}</span>
										</span>
									</div>
									<div class="flex flex-col items-end flex-grow p-4">
										<span>
											Pickup Date: <span class="font-medium">{{ bid.pickupDatetime | date : 'fullDate' }}</span>
										</span>
										<span>
											Delivery Date: <span class="font-medium">{{ bid.deliveryDatetime | date : 'fullDate' }}</span>
										</span>
										<span *ngIf="bid.transitTime">
											Transit Time: <span class="font-medium">{{ bid.transitTime }}</span>
										</span>
									</div>
									<div class="flex flex-col p-4 items-end">
										<span class="text-lg font-medium">{{ bid.amount | currency }}</span>
										<span class="text-base font-medium">({{ bid.currency }})</span>
										<button mat-raised-button color="primary" (click)="bookFreight(i, bI)" *ngIf="!freight.booked">Book</button>
									</div>
								</div>
								<div *ngIf="bid.comments" class="pb-2">Comments: {{ bid.comments }}</div>
							</div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel [expanded]="!freight.freightId">
						<mat-expansion-panel-header>Shipment Details</mat-expansion-panel-header>
						<div class="flex flex-col">
							<mat-divider></mat-divider>
							<div *ngFor="let shipment of freight.shipments; let sI = index" class="flex sm:border-b justify-between">
								<div class="flex flex-col p-4">
									<span class="font-medium">{{ shipment.description }}</span>
									<span>{{ shipment.originName }}</span>
									<span>{{ shipment.originAddress1 }}</span>
									<span>{{ shipment.originAddress2 }}</span>
									<div class="flex flex-row">
										{{ shipment.originCity }},
										{{ shipment.originState }}
										{{ shipment.originPostal }}
										{{ shipment.originCountry }}
									</div>
								</div>
								<div class="flex flex-col flex-grow items-end p-4">
									<span class="font-medium">{{ shipment.shipmentOrderNo ? 'Order: ' + shipment.shipmentOrderNo : '' }}</span>
									<span *ngIf="shipment.totalSkids"> {{ shipment.totalSkids | number }} Skid Slots </span>
									<span *ngFor="let handlingUnitDetail of handlingUnitSummary(shipment.shipmentHandlingUnits) | keyvalue">
										{{ handlingUnitDetail.value }} {{ handlingUnitDetail.key }}
									</span>
									<span *ngIf="shipment.totalLinearDimension"> {{ shipment.totalLinearDimension | number }} {{ shipment.dUnit }} </span>
									<span *ngIf="shipment.totalWeight"> {{ shipment.totalWeight | number }} {{ shipment.wUnit }} </span>
								</div>
								<div class="flex items-center w-40 p-4 justify-end">
									<mat-icon color="warn" *ngIf="!shipment.valid">error</mat-icon>
									<button mat-icon-button (click)="deleteShipment(i, sI)" *ngIf="!shipment.freightId">
										<mat-icon>delete</mat-icon>
									</button>
									<button mat-icon-button (click)="navigateToShipment(shipment.id)">
										<mat-icon *ngIf="!shipment.freightId">edit</mat-icon>
										<mat-icon *ngIf="shipment.freightId">info</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</mat-expansion-panel>
		</mat-accordion>
		<div
			class="flex flex-col w-2/5 self-start sticky top-0 max-h-[calc(100vh-8rem)] mat-elevation-z8 rounded-lg z-10"
			*ngIf="selectedFreight && !selectedFreight.booked"
		>
			<div>
				<div class="flex flex-row justify-between">
					<div class="flex flex-1 justify-center items-center font-medium p-4">
						<mat-icon class="mr-2">calendar_today</mat-icon>
						{{ selectedFreight.deliveryDate }}
					</div>
					<mat-divider vertical></mat-divider>
					<div class="flex flex-1 justify-center items-center font-medium p-4">
						<mat-icon class="mr-2">schedule</mat-icon>
						{{ deliveryTime }}
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="flex flex-row justify-between">
					<div class="flex flex-1 flex-col p-4" *ngIf="selectedFreight.shipments && selectedFreight.shipments.length">
						<mat-icon>place</mat-icon>
						<span>{{ selectedFreight.shipments[0].originName }}</span>
						<span>{{ selectedFreight.shipments[0].originAddress1 }}</span>
						<span>{{ selectedFreight.shipments[0].originAddress2 }}</span>
						<div class="flex flex-row">
							{{ selectedFreight.shipments[0].originCity }}
							{{ selectedFreight.shipments[0].originState }}
							{{ selectedFreight.shipments[0].originPostal }}
							{{ selectedFreight.shipments[0].originCountry }}
						</div>
					</div>
					<mat-divider vertical></mat-divider>
					<div class="flex flex-1 flex-col p-4">
						<mat-icon>tour</mat-icon>
						<span>{{ selectedFreight.deliveryName }}</span>
						<span>{{ selectedFreight.deliveryAddress1 }}</span>
						<span>{{ selectedFreight.deliveryAddress2 }}</span>
						<div class="flex flex-row">
							{{ selectedFreight.deliveryCity }},
							{{ selectedFreight.deliveryState }}
							{{ selectedFreight.deliveryPostal }}
							{{ selectedFreight.deliveryCountry }}
						</div>
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="flex flex-col pt-4 pl-4">
					<span *ngFor="let shipmentAccessorial of selectedShipmentAccessorials">{{ shipmentAccessorial }}</span>
				</div>
				<div class="flex flex-1 justify-between font-medium p-4">
					<div class="flex flex-row">{{ selectedTotalSkids | number }} Skid Slots</div>
					<div class="flex flex-col">
						<span *ngFor="let handlingUnit of selectedHandlingUnits | keyvalue"
							>{{ handlingUnit.value }} {{ handlingUnit.key }}{{ handlingUnit.value > 1 ? 'S' : '' }}</span
						>
					</div>
					<div class="flex flex-row">
						{{ selectedTotalLinearDimensions | number }}
						{{ selectedDUnit }}
					</div>
					<div class="flex flex-row">
						{{ selectedTotalWeight | number }}
						{{ selectedWUnit }}
					</div>
				</div>
			</div>
			<mat-divider></mat-divider>
			<app-broker-carrier
				class="flex relative h-full overflow-hidden"
				[projectId]="projectId"
				[shipmentIds]="validShipmentIds"
			></app-broker-carrier>
		</div>
	</div>
</div>
