<div class="m-4" *ngIf="quickQuoteForm" [formGroup]="quickQuoteForm">
	<div class="flex my-4 w-full">
		<div class="flex flex-col flex-1">
			<div class="flex flex-row">
				<mat-form-field appearance="outline" class="mr-2">
					<mat-label>Room Name</mat-label>
					<input matInput id="room-name" (change)="this.updateEstimates()" (focus)="inputFocused($event)" formControlName="room" />
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-20 mr-4">
					<mat-label>Count</mat-label>
					<input
						matInput
						id="count"
						type="number"
						(wheel)="$event.preventDefault()"
						(focus)="inputFocused($event)"
						formControlName="count"
					/>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-36 mr-2">
					<mat-label>Orientation</mat-label>
					<mat-select formControlName="orientation" (selectionChange)="generateEstimate()">
						<mat-option value="OPTIMIZE">Optimize</mat-option>
						<mat-option value="WIDTH">Width</mat-option>
						<mat-option value="LENGTH">Length</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="flex flex-row">
				<mat-form-field appearance="outline" class="w-28 mr-2">
					<mat-label>Width (ft)</mat-label>
					<input
						matInput
						id="width"
						(change)="generateEstimate()"
						(focus)="inputFocused($event)"
						type="number"
						(wheel)="$event.preventDefault()"
						formControlName="width"
					/>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-16 mr-4">
					<mat-label>(in)</mat-label>
					<input
						matInput
						id="width-in"
						(change)="generateEstimate()"
						(focus)="inputFocused($event)"
						type="number"
						formControlName="widthFraction"
					/>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-28 mr-2">
					<mat-label>Length (ft)</mat-label>
					<input
						matInput
						id="length"
						(change)="generateEstimate()"
						(focus)="inputFocused($event)"
						type="number"
						(wheel)="$event.preventDefault()"
						formControlName="length"
					/>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-16 mr-4">
					<mat-label>(in)</mat-label>
					<input
						matInput
						id="length-in"
						(change)="generateEstimate()"
						(focus)="inputFocused($event)"
						type="number"
						formControlName="lengthFraction"
					/>
				</mat-form-field>
			</div>
			<div class="flex flex-row">
				<mat-form-field appearance="outline" class="w-32 mr-4">
					<mat-label>Main Aisle (in)</mat-label>
					<input
						matInput
						id="main-aisle"
						(change)="generateEstimate()"
						(focus)="inputFocused($event)"
						type="number"
						formControlName="mainAisle"
					/>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-32 mr-4">
					<mat-label>Back Aisle (in)</mat-label>
					<input
						matInput
						id="back-aisle"
						(change)="generateEstimate()"
						(focus)="inputFocused($event)"
						type="number"
						formControlName="backAisle"
					/>
				</mat-form-field>
			</div>
			<div class="flex flex-row">
				<mat-form-field appearance="outline" class="w-28 mr-2">
					<mat-label>Height</mat-label>
					<mat-select formControlName="height" (selectionChange)="heightChange()">
						<mat-option *ngFor="let _ of [].constructor(13); let i = index" [value]="i + 8">{{ i + 8 }} ft</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-32 mr-2">
					<mat-label>Tiers</mat-label>
					<mat-select formControlName="tiers" (selectionChange)="generateEstimate()">
						<mat-option *ngFor="let _ of [].constructor(shelvingTiers); let i = index" [value]="i + 1"
							>{{ i + 1 }} Tier{{ i > 0 ? 's' : '' }}</mat-option
						>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-32 mr-4">
					<mat-label>Aisle (in)</mat-label>
					<input
						matInput
						id="aisle"
						(change)="generateEstimate()"
						(focus)="inputFocused($event)"
						type="number"
						(wheel)="$event.preventDefault()"
						formControlName="aisle"
					/>
				</mat-form-field>
			</div>
			<div class="flex flex-row">
				<mat-checkbox (change)="toggleAirFlow()" formControlName="airFlow">Air Flow</mat-checkbox>
				<mat-checkbox (change)="updateEstimates()" formControlName="tray">Tray</mat-checkbox>
				<mat-checkbox (change)="updateEstimates()" formControlName="lighting">Lightning</mat-checkbox>
				<mat-checkbox (change)="updateEstimates()" formControlName="mezzanine">Mezzanine</mat-checkbox>
				<!-- mat-checkbox formControlName="install">Install</mat-checkbox -->
			</div>
		</div>
		<div *ngIf="busy">
			<mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
		</div>
		<div class="flex flex-col mx-4 font-medium w-60" *ngIf="loaded">
			<div class="flex justify-between mb-2">
				<span>Orientation:</span>
				<span>{{ quickQuote.orientation }}</span>
			</div>
			<div class="flex justify-between">
				<span>Room Area:</span>
				<span>{{ roomArea / 144 | number : '1.0-2' }} sq ft</span>
			</div>
			<div class="flex justify-between mb-2">
				<span>Floor Coverage:</span>
				<span>{{ (quickQuote.shelfArea / roomArea) * 100 | number : '1.0-2' }}%</span>
			</div>
			<div class="flex justify-between">
				<span>Track Length:</span>
				<span>{{ (quickQuote.shelfWidth * quickQuote.widthCount + quickQuote.aisle) / 12 | number : '1.0-2' }} ft</span>
			</div>
			<div class="flex justify-between">
				<span>Carriage Length:</span>
				<span>{{ (quickQuote.shelfLength * quickQuote.lengthCount + quickQuote.extraShelfSize) / 12 | number : '1.0-2' }} ft</span>
			</div>
			<div class="flex justify-between mb-4">
				<span>Shelving Area:</span>
				<span>{{ quickQuote.shelfArea / 144 | number : '1.0-2' }} sq ft</span>
			</div>
			<div class="flex justify-between" [ngClass]="{'text-red': quickQuote.carriageError}">
				<span>{{ quickQuote.carriagePart }}</span>
				<span>{{ quickQuote.carriageQty }}</span>
			</div>
			<div class="flex justify-between" [ngClass]="{'text-red': quickQuote.trackError}">
				<span>{{ quickQuote.trackPart }}</span>
				<span>{{ quickQuote.trackQty }}</span>
			</div>
			<div class="flex justify-between mb-2" [ngClass]="{'text-red': quickQuote.trackHardwareError}">
				<span>{{ quickQuote.trackHardwarePart }}</span>
				<span>{{ quickQuote.trackHardwareQty }}</span>
			</div>
			<div class="flex justify-between" [ngClass]="{'text-red': quickQuote.extraTierComponentError}">
				<span>{{ quickQuote.extraTierComponentPart }}</span>
				<span>{{ quickQuote.extraTierComponentQty }}</span>
			</div>
			<div class="flex justify-between mb-2" [ngClass]="{'text-red': quickQuote.tierComponentError}">
				<span>{{ quickQuote.tierComponentPart }}</span>
				<span>{{ quickQuote.tierComponentQty }}</span>
			</div>
			<div *ngIf="quickQuote.extraShelfCount" class="flex justify-between" [ngClass]="{'text-red': quickQuote.extraShelfError}">
				<span>{{ quickQuote.extraShelfSize }}x{{ quickQuote.shelfWidth }} ({{ quickQuote.extraShelfPart }}):</span>
				<span>{{ quickQuote.extraShelfCount }}</span>
			</div>
			<div *ngIf="quickQuote.shelfCount" class="flex justify-between" [ngClass]="{'text-red': quickQuote.shelfError}">
				<span>{{ quickQuote.shelfLength }}x{{ quickQuote.shelfWidth }} ({{ quickQuote.shelfPart }}):</span>
				<span>{{ quickQuote.shelfCount }}</span>
			</div>
			<div class="flex justify-between mb-4">
				<span>Shelf Count:</span>
				<span>{{ quickQuote.shelfCount + quickQuote.extraShelfCount }}</span>
			</div>
			<div class="flex justify-between" [ngClass]="{'text-red': carriageErrors}">
				<span>Mobile Carriage:</span>
				<span>${{ quickQuote.carriageCost | number : '1.2-2' }}</span>
			</div>
			<div class="flex justify-between" [ngClass]="{'text-red': shelfErrors}">
				<span>Bulk Rack Shelving:</span>
				<span>${{ quickQuote.shelfCost | number : '1.2-2' }}</span>
			</div>
			<div *ngIf="quickQuoteForm.controls['airFlow'].value" class="flex justify-between">
				<span>Air Flow:</span>
				<span>${{ (quickQuote.shelfCost + quickQuote.carriageCost) * quickQuote.airFlowPct | number : '1.2-2' }}</span>
			</div>
			<div *ngIf="quickQuoteForm.controls['tray'].value && quickQuoteForm.controls['tray'].enabled" class="flex justify-between">
				<span>Trays:</span>
				<span>${{ (quickQuote.shelfCost + quickQuote.carriageCost) * quickQuote.trayPct | number : '1.2-2' }}</span>
			</div>
			<div *ngIf="quickQuoteForm.controls['lighting'].value" class="flex justify-between">
				<span>Lighting:</span>
				<span>${{ (quickQuote.shelfCost + quickQuote.carriageCost) * quickQuote.lightingPct | number : '1.2-2' }}</span>
			</div>
			<div *ngIf="quickQuoteForm.controls['mezzanine'].value && quickQuoteForm.controls['mezzanine'].enabled" class="flex justify-between">
				<span>Mezzanine:</span>
				<span>${{ (quickQuote.shelfCost + quickQuote.carriageCost) * quickQuote.mezzaninePct | number : '1.2-2' }}</span>
			</div>
			<div *ngIf="quickQuoteForm.controls['install'].value" class="flex justify-between">
				<span>Install:</span>
				<span>${{ (quickQuote.shelfCost + quickQuote.carriageCost) * quickQuote.installPct | number : '1.2-2' }}</span>
			</div>
			<div class="flex justify-between" [ngClass]="{'text-red': hasErrors}">
				<span>Room Estimate:</span>
				<span>${{ totalCost | number : '1.2-2' }}</span>
			</div>
			<div *ngIf="hasErrors" class="text-red">* Estimate is INVALID - missing parts/pricing required for the configured dimensions.</div>
		</div>
		<div class="flex flex-col w-72" *ngIf="loaded">
			<!-- div class="sticky top-0">
        <div class="flex">
          <button
            mat-icon-button
            [disabled]="!quickQuoteForm.controls.zoom.value || quickQuoteForm.controls.zoom.value <= 1"
            (click)="zoomOut()"
            [matTooltip]="'Zoom Out'"
          >
            <mat-icon>zoom_out</mat-icon>
          </button>
          <mat-slider min="1" max="5" step="1" showTickMarks (change)="setZoom()">
            <input matSliderThumb formControlName="zoom" />
          </mat-slider>
          <button
            mat-icon-button
            [disabled]="!quickQuoteForm.controls.zoom.value || quickQuoteForm.controls.zoom.value >= 5"
            (click)="zoomIn()"
            [matTooltip]="'Zoom In'"
          >
            <mat-icon>zoom_in</mat-icon>
          </button>
        </div>
      </div -->
			<div #room class="border border-black border-opacity-80 flex w-full flex-col" [style]="{height: heightClass}">
				<div *ngIf="quickQuote.orientation === 'WIDTH'" class="flex">
					<div class="bg-red bg-opacity-80" [style]="{height: backAisleClass, width: shelfSpanClass}"></div>
				</div>
				<div *ngFor="let _ of [].constructor(lengthSplit1)" class="flex">
					<div
						*ngIf="quickQuote.orientation === 'LENGTH'"
						class="bg-red bg-opacity-80"
						[style]="{height: shelfHeightClass, width: backAisleClass}"
					></div>
					<div
						*ngFor="let _ of [].constructor(widthSplit1)"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: shelfHeightClass, width: shelfWidthClass}"
					></div>
					<div
						*ngIf="quickQuote.orientation === 'WIDTH'"
						class="border border-black"
						[style]="{height: shelfHeightClass, width: aisleClass}"
					></div>
					<div
						*ngFor="let _ of [].constructor(widthSplit2)"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: shelfHeightClass, width: shelfWidthClass}"
					></div>
					<div
						*ngIf="quickQuote.orientation === 'LENGTH' && quickQuote.extraShelfSize > 0"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: shelfHeightClass, width: extraShelfClass}"
					></div>
					<div
						*ngIf="quickQuote.orientation === 'LENGTH'"
						class="bg-red bg-opacity-80"
						[style]="{height: shelfHeightClass, width: mainAisleClass}"
					></div>
				</div>

				<div *ngIf="quickQuote.orientation === 'LENGTH'" class="flex">
					<div class="bg-red bg-opacity-80" [style]="{height: aisleClass, width: backAisleClass}"></div>
					<div
						*ngFor="let _ of [].constructor(quickQuote.widthDisplayCount)"
						class="border border-black"
						[style]="{height: aisleClass, width: shelfWidthClass}"
					></div>
					<div
						*ngIf="quickQuote.extraShelfSize > 0"
						class="border border-black"
						[style]="{height: aisleClass, width: extraShelfClass}"
					></div>
					<div
						*ngIf="quickQuote.orientation === 'LENGTH'"
						class="bg-red bg-opacity-80"
						[style]="{height: aisleClass, width: mainAisleClass}"
					></div>
				</div>
				<div *ngFor="let _ of [].constructor(lengthSplit2)" class="flex">
					<div
						*ngIf="quickQuote.orientation === 'LENGTH'"
						class="bg-red bg-opacity-80"
						[style]="{height: shelfHeightClass, width: backAisleClass}"
					></div>
					<div
						*ngFor="let _ of [].constructor(widthSplit1)"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: shelfHeightClass, width: shelfWidthClass}"
					></div>
					<div
						*ngIf="quickQuote.orientation === 'WIDTH'"
						class="border border-black"
						[style]="{height: shelfHeightClass, width: aisleClass}"
					></div>
					<div
						*ngFor="let _ of [].constructor(widthSplit2)"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: shelfHeightClass, width: shelfWidthClass}"
					></div>
					<div
						*ngIf="quickQuote.orientation === 'LENGTH' && quickQuote.extraShelfSize > 0"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: shelfHeightClass, width: extraShelfClass}"
					></div>
					<div
						*ngIf="quickQuote.orientation === 'LENGTH'"
						class="bg-red bg-opacity-80"
						[style]="{height: shelfHeightClass, width: mainAisleClass}"
					></div>
				</div>
				<div *ngIf="quickQuote.orientation === 'WIDTH' && quickQuote.extraShelfSize > 0" class="flex">
					<div
						*ngFor="let _ of [].constructor(widthSplit1)"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: extraShelfClass, width: shelfWidthClass}"
					></div>
					<div class="border border-black" [style]="{height: extraShelfClass, width: aisleClass}"></div>
					<div
						*ngFor="let _ of [].constructor(widthSplit2)"
						class="border border-black bg-green bg-opacity-20"
						[style]="{height: extraShelfClass, width: shelfWidthClass}"
					></div>
				</div>
				<div *ngIf="quickQuote.orientation === 'WIDTH'" class="flex">
					<div class="bg-red bg-opacity-80" [style]="{height: mainAisleClass, width: shelfSpanClass}"></div>
				</div>
			</div>
		</div>
	</div>
</div>
