<h2 mat-dialog-title>Override for {{ data.date | date : 'fullDate' }}</h2>
<mat-dialog-content class="mat-typography">
	<div class="flex flex-col" [formGroup]="overrideForm" *ngIf="!loading">
		<div class="text-xs font-medium ml-2 mb-4" *ngIf="admin">Note: 0 and blank are different!</div>
		<ng-container formArrayName="taskOverrides">
			<ng-container *ngFor="let override of taskOverrides.controls; let i = index" formGroupName="{{ i }}">
				<div class="flex">
					<div class="w-24 m-2 font-medium">
						<div class="mt-2 mb-2">{{ override.value.name }}:</div>
						<mat-error class="text-sm" *ngIf="showMessage(override.value)">*Override will be removed.</mat-error>
					</div>
					<div class="m-2">
						<mat-form-field appearance="outline" class="w-20" *ngIf="admin">
							<mat-label>Hours</mat-label>
							<input matInput type="number" (wheel)="$event.preventDefault()" formControlName="hours" />
							<mat-error>Hours <strong>must be positive</strong>.</mat-error>
						</mat-form-field>
						<div *ngIf="!admin">{{ override.value.hours }}</div>
					</div>
					<div class="m-2">
						<mat-form-field appearance="outline" *ngIf="admin">
							<mat-label>Comments</mat-label>
							<textarea matInput type="text" formControlName="comment"></textarea>
						</mat-form-field>
						<div *ngIf="!admin">{{ override.value.comment }}</div>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
	<div class="m-8" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!loading">
	<button mat-raised-button mat-dialog-close *ngIf="!busy">Cancel</button>
	<button mat-raised-button color="primary" (click)="saveOverrides()" *ngIf="!busy && admin">Save</button>
	<div class="m-8" *ngIf="busy">
		<mat-spinner diameter="20"></mat-spinner>
	</div>
</mat-dialog-actions>
